import bpa_2024_07_01 from '../../../assets/files/ru/Perechen_bankovskih_platezhnyh_agentov_01_07_2024.pdf';
import bpa_2024_09_13 from '../../../assets/files/ru/perechen-bankovskih-platezhnyh-agentov-13-09-2024.pdf';
import bpa_2024_10_01 from '../../../assets/files/ru/perechen-bankovskih-platezhnyh-agentov-01-10-2024.pdf';
import bpa_2024_01_01 from '../../../assets/files/ru/perechen-bankovskih-platezhnyh-agentov-01-01-2025.pdf';
import bpa_2025_02_27 from '../../../assets/files/ru/perechen-bankovskih-platezhnyh-agentov-27-02-2025.pdf';
import bpa_actual from '../../../assets/files/ru/perechen-bankovskih-platezhnyh-agentov-01-04-2025.pdf';

export default {
  seo: {
    title: 'Перечень БПА | Банк 131',
    description: 'Перечень БПА'
  },
  title: 'Перечень БПА',
  content: [
    {
      label: 'Дата размещения 01.04.2025',
      title: 'Перечень банковских платежных агентов на дату 01.04.2025 г.pdf',
      link: bpa_actual,
    },
    {
      title: 'Архив',
      items: [
        {
          label: 'Дата размещения 27.02.2025',
          title: 'Перечень банковских платежных агентов на дату 27.02.2025 г.pdf',
          link:  bpa_2025_02_27,
        },
        {
          label: 'Дата размещения 01.01.2025',
          title: 'Перечень банковских платежных агентов на дату 01.01.2025 г.pdf',
          link: bpa_2024_01_01,
        },
        {
          label: 'Дата размещения 01.10.2024',
          title: 'Перечень банковских платежных агентов на дату 01.10.2024 г.pdf',
          link: bpa_2024_10_01,
        },
        {
          label: 'Дата размещения 13.09.2024',
          title: 'Перечень банковских платежных агентов на дату 13.09.2024 г.pdf',
          link: bpa_2024_09_13,
        },
        {
          label: 'Дата размещения 01.07.2024',
          title: 'Перечень банковских платежных агентов на дату 01.07.2024 г.pdf',
          link: bpa_2024_07_01,
        },
      ],
    },
  ],
};
